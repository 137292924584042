/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createSlice } from '@reduxjs/toolkit';
import type {Complaints,Doctor, DoctorPatientRelation,Patient} from '../../types/ninox.types';

const DOCTORS_SLICE = 'DOCTORS';

const initialState = {
  doctorsAppointmentsStats: [],
  loadDoctors: false,
  isDoctorSelecetd: false,
  doctorFreeSlots: [],
  doctorFreeDates: [],
  doctorAbsences: [],
  doctorsList: [],
};

export const doctorsSlice = createSlice({
  name: DOCTORS_SLICE,
  initialState,
  reducers: {
    setDoctorsAppointmentsStats: (state, action) => {
      state.doctorsAppointmentsStats = action.payload;
    },
    setLoadDoctors: (state, action) => {
      state.loadDoctors = action.payload;
    },
    setIsDoctorSelected: (state, action) => {
      state.isDoctorSelecetd = action.payload;
    },
    setdoctorFreeSlots: (state, action) => {
      state.doctorFreeSlots = action.payload;
    },
    setdoctorFreeDates: (state, action) => {
      state.doctorFreeDates = action.payload;
    },
    setDocotrAbsences: (state, action) => {
      state.doctorAbsences = action.payload;
    },
    setDoctorAbsenceList: (state, action) => {
      // @ts-ignore
      state.doctorAbsences = [...state.doctorAbsences, action.payload];
    },
    setDeletedAbsence: (state, action) => {
      const newAbsences = [...state.doctorAbsences];
      newAbsences.splice(newAbsences.findIndex((item: any) => item.id === action.payload), 1);
      state.doctorAbsences = newAbsences;
    },
    setDoctotrsList: (state, action) => {
      state.doctorsList = action.payload;
    },
    setUpdateDoctotrAbsense: (state, action) => {
      // @ts-ignore
      state.doctorAbsences = state.doctorAbsences.map((absence) => ((absence.id === action.payload.id) ? { ...absence, ...action.payload } : absence));
    },
    fetchDoctorsAppointmentsStats: () => {}, // call watcherSaga.
    fetchDoctorFreeSlots: () => {}, // call watcherSaga.
    fetchDoctorAbsences: () => {}, // call watcherSaga.
    createDoctorAbsence: () => {}, // call watcherSaga.
    fetchAllDoctors: () => {},  // call watcherSaga.
    deleteAbsense: () => {}, // call watcherSaga.
    saveAbsense: () => {}, // call watcherSaga.
  },
});

export const {
  setDoctorsAppointmentsStats,
  fetchDoctorsAppointmentsStats,
  setLoadDoctors,
  setIsDoctorSelected,
  setdoctorFreeSlots,
  fetchDoctorFreeSlots,
  setdoctorFreeDates,
  fetchDoctorAbsences,
  setDocotrAbsences,
  setDoctorAbsenceList,
  createDoctorAbsence,
  deleteAbsense,
  setDeletedAbsence,
  fetchAllDoctors,
  setDoctotrsList,
  saveAbsense,
  setUpdateDoctotrAbsense,
} = doctorsSlice.actions;

export const doctorsSliceSelector = (state: any) => state.doctors;

export default doctorsSlice.reducer;
  