import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames/bind';
import type { Appointment, DoctorPatientRelation, Practice } from '../../../../types/ninox.types';
import { getLocalizedDate, getLocalizedDateTime } from '../../../../utils';
import { emptyAppointment } from '../../../../utils/emptyModels';
import FormDialog from '../../FormDialog/FormDialog';
import FormField from '../../FormField/FormField';
import style from '../Modal.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { patientInfoSliceSelector, setPatientInfoUpdate } from '../../../../features/patientInfo/patientInfoSlice';
import _ from 'lodash';
import { createPatientAppointment, fetchDoctorAppointments, fetchDoctorPractices } from '../../../../utils/apiCalls';

const cx = classNames.bind(style);

export type AppointmentModalProps = {
  type: 'appointment';
  show: boolean;
  setShow: (show: boolean) => void;
  appointment: Appointment | null;
  patientId: number;
  doctors: DoctorPatientRelation[];
};

export function AppointmentModal(props: AppointmentModalProps): JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [doctorAppointments, setDoctorAppointments] = useState<Appointment[]>([]);
  const [doctorPractices, setDoctorPractices] = useState<Practice[]>([]);
  const [doctorDateAppointments, setDoctorDateAppointments] = useState<Appointment[]>([]);
  const [appointment, setAppointment] = useState<Appointment>(emptyAppointment);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { patientInfoUpdate } = useSelector(patientInfoSliceSelector);


  const filterDoctorAppointmentsForDate = (appointments: Appointment[]): Appointment[] => appointments.filter((doctorAppointment) => getLocalizedDate(intl, doctorAppointment.startDate) === getLocalizedDate(intl, appointment.startDate));

  const createAppointment = () => {
    if (appointment.type === 'offline' && appointment.practiceId === 0) {
      setErrorMessage('Keine Praxis gewählt');
      return;
    }
    if (appointment.doctorId === 0) {
      setErrorMessage('Kein Arzt gewählt');
      return;
    }
    if (appointment.startDate === '') {
      setErrorMessage('Kein Startdatum gewählt');
      return;
    }

    props.doctors.forEach((doctor) => {
      if (doctor.doctorId === appointment.doctorId) {
        appointment.doctorName = `${doctor.doctor?.firstname} ${doctor.doctor?.lastname}`;
      }
    });

    createPatientAppointment(appointment).then((response) => {
      if (response.error) {
        setErrorMessage('Es ist ein Fehler aufgetreten');
        if (response.data.response.status === 400) {
          setErrorMessage('Terminkollision');
        }
        return;
      }
      props.setShow(false);
      setAppointment({ ...emptyAppointment, patientId: props.patientId });
      appointment.id ='';
      appointment.type = appointment.type === 'online' ? '1' : '2';
      const appointmentUpdated = _.cloneDeep([...patientInfoUpdate.appointment]);
      appointmentUpdated.push(appointment);
      dispatch(setPatientInfoUpdate({...patientInfoUpdate, appointment: appointmentUpdated}));
    });
  };

  useEffect(() => {
    if (props.appointment) {
      setAppointment(props.appointment);
    }
  }, [props.appointment]);

  useEffect(() => {
    fetchDoctorAppointments(appointment.doctorId).then((response) => {
      if (response.error) {
        return;
      }
      setDoctorAppointments(response.data);
      setDoctorDateAppointments(filterDoctorAppointmentsForDate(response.data));
    });
    fetchDoctorPractices(appointment.doctorId).then((response) => {
      if (response.error) {
        return;
      }
      setDoctorPractices(response.data);
    });
  }, [appointment.doctorId]);

  useEffect(() => {
    if (appointment.startDate) {
      setDoctorDateAppointments(filterDoctorAppointmentsForDate(doctorAppointments));
      const startDate = new Date(appointment.startDate).getTime();
      const oneHour = 60 * 60 * 1000;
      const timeOffset = (new Date().getTimezoneOffset() / 60) * -1;
      const thirtyMinutes = 30 * 60 * 1000;
      const fifteenMinutes = 15 * 60 * 1000;

      const endDate = new Date(
        startDate
          + oneHour * timeOffset
          + (appointment.appointmentType === 'followUpAppointment' ? fifteenMinutes : thirtyMinutes),
      )
        .toISOString()
        .replace('Z', '');

      setAppointment({ ...appointment, endDate });
    }
  }, [appointment.startDate, appointment.appointmentType]);

  useEffect(() => {
    setAppointment({ ...appointment, patientId: props.patientId });
  }, [props.patientId]);

  const listClasses = cx({ listHeight: true });
  const errorMessageClasses = cx({ errorMessage: true });

  return (
    <FormDialog
      title={intl.formatMessage({ id: 'createAppointment' })}
      onSave={() => createAppointment()}
      onClose={() => props.setShow(false)}
      setShow={() => props.setShow(false)}
      show={props.show}
    >
      <FormField
        type='select'
        name='doctorId'
        value={appointment.doctorId.toString()}
        onChange={(event) => setAppointment({ ...appointment, doctorId: Number(event.target.value) })}
        choices={[['0', '']].concat(
          props.doctors.map((doctor) => [
            doctor.doctorId?.toString() || '0',
            `${doctor.doctor?.title ? intl.formatMessage({ id: doctor.doctor.title }) : ''} 
            ${doctor.doctor?.firstname} ${doctor.doctor?.lastname}`,
          ]),
        )}
      >
        <FormattedMessage id='doctor' />
      </FormField>
      <FormField
        type='select'
        name='type'
        value={appointment.type}
        onChange={(event) => setAppointment({ ...appointment, type: event.target.value })}
        choices={[
          ['online', 'Online'],
          ['offline', 'Offline'],
        ]}
      >
        <FormattedMessage id='appointmentType' />
      </FormField>
      {appointment.type === 'offline' && appointment.doctorId !== 0 && (
        <FormField
          type='select'
          name='type'
          value={appointment.practiceId.toString()}
          onChange={(event) => setAppointment({ ...appointment, practiceId: Number(event.target.value) })}
          choices={[['0', '']].concat(
            doctorPractices.map((practice) => [practice.id?.toString() || '0', `${practice.name}`]),
          )}
        >
          <FormattedMessage id='practice' />
        </FormField>
      )}
      <FormField
        type='select'
        name='appointmentSubtype'
        value={appointment.appointmentType}
        onChange={(event) => setAppointment({ ...appointment, appointmentType: event.target.value })}
        choices={[
          ['firstAppointment', 'Erstgespräch'],
          ['followUpAppointment', 'Folgetermin'],
        ]}
      >
        <FormattedMessage id='appointmentSubtype' />
      </FormField>
      <FormField
        type='datetime-local'
        name='startDate'
        value={appointment.startDate}
        onChange={(event) => setAppointment({ ...appointment, startDate: event.target.value })}
      >
        <FormattedMessage id='startData' />
      </FormField>
      <FormField
        type='datetime-local'
        name='endDate'
        value={appointment.endDate}
        onChange={(event) => setAppointment({ ...appointment, endDate: event.target.value })}
      >
        <FormattedMessage id='endDate' />
      </FormField>
      <FormField
        type='textarea'
        placeholder={intl.formatMessage({ id: 'patient.enterRemark' })}
        name='comment'
        value={appointment.comment}
        onChange={(event) => setAppointment({ ...appointment, comment: event.target.value })}
      >
        <FormattedMessage id='patient.message' />
      </FormField>
      <div>
        <FormattedMessage id='doctorAppointments' />:
      </div>
      <div className={listClasses}>
        {doctorDateAppointments.length === 0 && <FormattedMessage id='noAppointments' />}
        {doctorDateAppointments.map((doctorAppointment) => (
          <div key={doctorAppointment.id}>
            {getLocalizedDateTime(intl, doctorAppointment.startDate)} <b>-</b>
            {getLocalizedDateTime(intl, doctorAppointment.endDate)}
          </div>
        ))}
      </div>
      <div className={errorMessageClasses}>{errorMessage}</div>
    </FormDialog>
  );
}
