import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Header, Segment } from 'semantic-ui-react';
import style from './DoctorDetails.module.scss';
import { doctorsSliceSelector, fetchDoctorFreeSlots } from '../doctorsSlice';
import calculationsService from '../../../services/calculations.service';

export default function DoctorInfoAppointments(props: any) {
  const { doctorId } = props;
  const { doctorFreeSlots, doctorFreeDates } = useSelector(doctorsSliceSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!doctorFreeSlots || !doctorFreeSlots.length) {
      dispatch(fetchDoctorFreeSlots(doctorId));
    }
  }, []);

  const rendeDates = () => (!doctorFreeSlots.length ?
    (
      <Header>The doctor not have avaliable slots</Header>
    )
    :
    doctorFreeDates.map((date: any, i:number) => {
      const dateFirstAppointments = doctorFreeSlots?.filter(
        (slot: any) => calculationsService.isSameDay(date, slot.start) && slot.type === 0,
      );

      const dateFollowAppointments = doctorFreeSlots?.filter(
        (slot: any) => calculationsService.isSameDay(date, slot.start) && slot.type === 1,
      );

      return (
        <Segment raised className={style.DoctorSecondRowContainer} key={String(date)}>
          <div className={style.DateContainer}>
            <p className={style.BlueTextBold}>{calculationsService.getDateFormat(date)}</p>
          </div>
          {dateFirstAppointments && dateFirstAppointments.length !== 0 && (
            <div>
              <p className={style.GreenText}>Online Onboarding</p>
              <div className={style.SlotContainer}>
                {dateFirstAppointments.map((slot: any) => (
                  <div className={style.GreenSlot} key={slot.start}>
                    <span className={style.SlotTextGreen}>{`${calculationsService.getHourMinutesFormat(
                      slot.start,
                    )} - ${calculationsService.getHourMinutesFormat(slot.end)} Uhr`}</span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {dateFollowAppointments && dateFollowAppointments.length !== 0 && (
            <div>
              <p className={style.BlueText}>
                <FormattedMessage id='followAppointments' />
              </p>
              <div className={style.SlotContainer}>
                {dateFollowAppointments.map((slot: any) => (
                  <div className={style.BlueSlot} key={slot.start}>
                    <span className={style.SlotTextBlue}>{`${calculationsService.getHourMinutesFormat(
                      slot.start,
                    )} - ${calculationsService.getHourMinutesFormat(slot.end)} Uhr`}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Segment>
      );
    }

    )
  
  );

  return (
    <div>
      {rendeDates()}
    </div>
  );
}
