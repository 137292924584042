import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Appointment, Practice } from '../../../../types/ninox.types';
import FormDialog from '../../FormDialog/FormDialog';
import FormField from '../../FormField/FormField';
import { createOfflinePracticeAppointment } from '../../../../utils/apiCalls';

export type OfflinePracticeAppointmentModalProps = {
    onSubmit(updatedAppointment: { patient: { firstname: string; lastname: string; birthday: string; email: string; mobile: string; }; id?: string; comment: string; carriedOut: string; type: string; appointmentType: string; patientId: number; patientName?: string; doctorId: number; doctorName?: string; practiceId: number; startDate: string; endDate: string; practice?: Practice; }): unknown;
    type: 'practiceAppointment';
    show: boolean;
    appointment: Appointment | null;
    setShow: (show: boolean) => void;
    reloadSlots: () => void;
};

export function OfflinePracticeAppointmentModal(props: OfflinePracticeAppointmentModalProps): JSX.Element {
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [birthday, setBirthday] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const intl = useIntl();

  const handleSubmit = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    
    if (!props.appointment) return;
    
    const updatedAppointment = {
      ...props.appointment,
      patient: {
        firstname,
        lastname,
        birthday,
        email,
        mobile,
      },
    }; 
    const token = String(localStorage.getItem('Token'));

    return createOfflinePracticeAppointment(token, updatedAppointment).then(
      (response) => {
        
        setIsSubmitting(false);
        if (response.status !== 200) {
          alert(response.response.data.message);
          return;
        }
        props.setShow(false);
        if (props.reloadSlots) {
          props.reloadSlots();
        }
      });
  };

  return (
    <div className="offline-appointment-modal">
      <FormDialog
        title={intl.formatMessage({ id: 'createAppointment' })}
        onSave={handleSubmit}
        textSave={isSubmitting ? 'Loading..' : 'Save'}
        variantSave={isSubmitting ? 'light' : 'primary'}
        onClose={() => props.setShow(false)}
        setShow={() => props.setShow(false)}
        show={props.show}
      >
        <p>
          bei <strong> {(props.appointment as any)?.doctor?.doctorName}</strong> um{' '}
          <strong>
            {new Date((props.appointment as any)?.start).toLocaleTimeString('de-DE', {
              hour: '2-digit',
              minute: '2-digit',
            })}{' '}
                      Uhr.
          </strong>
        </p>
        <FormField
          type="text"
          name="firstname"
          value={firstname}
          onChange={(e) => setFirstname(e.target.value)}
        >
          <FormattedMessage id="Vorname" />
        </FormField>
        <FormField
          type="text"
          name="lastname"
          value={lastname}
          onChange={(e) => setLastname(e.target.value)}
        >
          <FormattedMessage id="Nachname" />
        </FormField>
        <FormField
          type="date"
          name="birthday"
          value={birthday}
          onChange={(e) => setBirthday(e.target.value)}
        >
          <FormattedMessage id="Geburtstag" />
        </FormField>
        <FormField
          type="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        >
          <FormattedMessage id="Email" />
        </FormField>
        <FormField
          type="tel"
          name="mobile"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
        >
          <FormattedMessage id="mobile" />
        </FormField>
      </FormDialog>
    </div>
  );
}

export default OfflinePracticeAppointmentModal;